exports.components = {
  "component---coloreshtml-template-color-page-js": () => import("./../../../coloreshtml/template/ColorPage.js" /* webpackChunkName: "component---coloreshtml-template-color-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-tematicas-js": () => import("./../../../src/pages/tematicas.js" /* webpackChunkName: "component---src-pages-tematicas-js" */),
  "component---src-template-index-page-js": () => import("./../../../src/template/IndexPage.js" /* webpackChunkName: "component---src-template-index-page-js" */),
  "component---src-template-legal-page-js": () => import("./../../../src/template/LegalPage.js" /* webpackChunkName: "component---src-template-legal-page-js" */),
  "component---src-template-page-palette-color-js": () => import("./../../../src/template/PagePaletteColor.js" /* webpackChunkName: "component---src-template-page-palette-color-js" */),
  "component---src-template-page-tono-palette-js": () => import("./../../../src/template/PageTonoPalette.js" /* webpackChunkName: "component---src-template-page-tono-palette-js" */),
  "component---src-template-post-template-js": () => import("./../../../src/template/PostTemplate.js" /* webpackChunkName: "component---src-template-post-template-js" */),
  "component---src-template-scheme-template-js": () => import("./../../../src/template/SchemeTemplate.js" /* webpackChunkName: "component---src-template-scheme-template-js" */),
  "component---src-template-tematic-post-js": () => import("./../../../src/template/TematicPost.js" /* webpackChunkName: "component---src-template-tematic-post-js" */),
  "component---src-template-tool-template-js": () => import("./../../../src/template/ToolTemplate.js" /* webpackChunkName: "component---src-template-tool-template-js" */)
}

